import React, { useState } from "react"
import { ErrorMessage, Field, Form, Formik } from "formik"
import { Col, Container, Modal, Row } from "react-bootstrap"
import smile from "../../../images/smile.png"
import userIcon from "../../../images/icons8-user-24.png"
import emailIcon from "../../../images/icons8-email-24.png"
import phoneIcon from "../../../images/icons8-ringing-phone-24.png"
import { MobileButton } from "../button/button"
import { InputStyled, ContactFormStyled, ModalStyled } from "./contactFormStyle"
import * as Yup from "yup"

import { injectIntl } from "gatsby-plugin-intl"
import textIcon from "@/images/icons8-document-24.png"

const ContactForm = ({ title, intl }) => {
  const [formConfirmation, setFormConfirmation] = useState(false)
  const handleShowFormConfimation = () => {
    setFormConfirmation(true)
  }
  const handleCloseFormConfirmation = () => {
    setFormConfirmation(false)
  }

  const ContactSchema = Yup.object().shape({
    name: Yup.string()
      .min(5, intl.formatMessage({ id: "form.error.tooShort" }))
      .max(50, intl.formatMessage({ id: "form.error.tooLong" }))
      .required(intl.formatMessage({ id: "form.error.name" })),
    email: Yup.string()
      .email(intl.formatMessage({ id: "form.error.mail" }))
      .required(intl.formatMessage({ id: "form.error.mail" })),
    phone: Yup.number()
      .typeError(intl.formatMessage({ id: "form.error.tel" }))
      .required(intl.formatMessage({ id: "form.error.tel" })),
    message: Yup.string()
      .max(500, intl.formatMessage({ id: "form.error.tooLong" }))
      .required(intl.formatMessage({ id: "form.error.message" })),
  })

  return (
    <ContactFormStyled>
      <Formik
        initialValues={{
          name: "",
          email: "",
          phone: "",
          message: "",
        }}
        validationSchema={ContactSchema}
        onSubmit={(values, { resetForm }) => {
          //send mail
          values.title = title
          values.design = '1'

          console.log("form send ", values)
          fetch("/form-process.php", {
            method: "POST",
            body: JSON.stringify(values),
            mode: "cors",
            cache: "no-cache",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
            redirect: "follow",
            referrer: "no-referrer",
          })
            .then(() => {
              resetForm()
              handleShowFormConfimation()
            })
            .catch(err => console.log(err))
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="form-content">
              <h1>{intl.formatMessage({ id: "form.title" })}</h1>
              <p className={"form-subtitle"}>
                {intl.formatMessage({ id: "form.content" })}
                <span>aranzacje@willbud.pl</span>
              </p>
              <div className="form-container">
                <Container fluid>
                  <Row>
                    <Col md={"12"} className={"input-column"}>
                      <div className={"input-wrapper"}>
                        <InputComponent
                          name="name"
                          style={
                            errors.name &&
                            touched.name && {
                              border: "2px solid #e00000",
                            }
                          }
                          placeholder={intl.formatMessage({
                            id: "form.input.name",
                          })}
                          icon={userIcon}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={"12"} className={"input-column"}>
                      <div className={"input-wrapper"}>
                        <InputComponent
                          name="phone"
                          style={
                            errors.phone &&
                            touched.phone && { border: "2px solid #e00000" }
                          }
                          placeholder={intl.formatMessage({
                            id: "form.input.tel",
                          })}
                          icon={phoneIcon}
                        />
                      </div>
                    </Col>
                    <Col md={"12"} className={"input-column"}>
                      <div className={"input-wrapper"}>
                        <InputComponent
                          name="email"
                          style={
                            errors.email &&
                            touched.email && { border: "2px solid #e00000" }
                          }
                          placeholder={intl.formatMessage({
                            id: "form.input.mail",
                          })}
                          icon={emailIcon}
                        />
                      </div>
                    </Col>
                    <Col md={"12"} className={"input-column"}>
                      <div className={"input-wrapper"}>
                        <InputComponent
                          name={"message"}
                          component={"textarea"}
                          rows="6"
                          intl={intl}
                          style={
                            errors.message &&
                            touched.message && {
                              border: "2px solid #e00000",
                            }
                          }
                          placeholder={intl.formatMessage({
                            id: "form.text.message.placeholder",
                          })}
                          icon={textIcon}
                          textarea={true}
                        />
                      </div>
                    </Col>
                    <Col md={12} className={"input-column"}>
                      <p className={"checkbox-name"}>
                        {intl.formatMessage({ id: "form.agreement" })}
                      </p>
                    </Col>

                    <Col md={"12"} className={"button-column"}>
                      <MobileButton
                        innerText={intl.formatMessage({
                          id: "form.send.button",
                        })}
                        bg={"#9ecd49"}
                        color={"#fff"}
                        center={true}
                        handleAction={() => {
                          typeof window !== "undefined" &&
                            window.gtag_report_conversion &&
                            window.gtag_report_conversion()
                          typeof window !== "undefined" &&
                            window.fbq &&
                            window.fbq("track", "Lead")

                          window.gtag("event", "conversion", {
                            send_to: "AW-761411387/n2Y1CIapjd0CELvuiOsC",
                          })
                        }}
                      />
                      <ModalConfirmation
                        handleclose={handleCloseFormConfirmation}
                        show={formConfirmation}
                        onHide={handleCloseFormConfirmation}
                        contactConfirmModal={true}
                      />
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </ContactFormStyled>
  )
}

export const InputComponent = ({
  name,
  placeholder,
  style,
  icon,
  textarea,
  id,
  select,
  intl,
}) => {
  return (
    <InputStyled textarea={textarea}>
      <label>
        {textarea && (
          <div className="textarea-head">
            <div className="icon icon-textarea">
              <img src={icon} alt="" />
            </div>
            <div>
              <p>{intl && intl.formatMessage({ id: "form.text.message" })}</p>
              <p>{id !== "false" && id}</p>
            </div>
          </div>
        )}
        <Field
          name={name}
          placeholder={placeholder}
          style={style}
          component={textarea ? "textarea" : select ? "select" : "input"}
          rows={7}
        >
          {select && (
            <>
              <option value="">
                {intl &&
                  intl.formatMessage({ id: "investors.form.input.amount" })}
              </option>
              <option value="50 000">50 000</option>
              <option value="100 000">100 000</option>
              <option value="200 000">200 000</option>
              <option value="500 000">500 000</option>
            </>
          )}
        </Field>
        {!textarea && (
          <div className="icon">
            <img src={icon} alt="" />
          </div>
        )}
      </label>
      <ErrorMessage
        component={"p"}
        className={"error-message"}
        style={textarea && { bottom: "-8px" }}
        name={name}
      />
    </InputStyled>
  )
}

const ModalConfirmation = props => {
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable={false}
      dialogClassName={"contact-confirm-modal overflow-hidden"}
    >
      <ModalStyled>
        <div className="modal-container">
          <div className="close-button-area" onClick={props.handleclose}>
            <div className="close-button-modal" />
          </div>
          <div className="icon">
            {!props.investorsView && <img src={smile} alt="" />}
          </div>
          <div className="content">
            <p>Twoja wiadomość została wysłana</p>

            <p>Dziękujemy za kontakt</p>
          </div>
        </div>
      </ModalStyled>
    </Modal>
  )
}

export default injectIntl(ContactForm)
