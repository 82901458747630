import React from "react"

import Helmet from "react-helmet"
import Layout from "@/components/layout"

import { HeaderContainer } from "@/components/estates/janowo/janowoStyle"
import EstateTitle from "@/components/estates/estateTitle"
import { Col, Container, Row } from "react-bootstrap"

import img from "@/images/z-18/pod-klucz/WpK_salon_pakiet_platynowy.jpg"
import { ProgramInfoBarContainer } from "@/components/program/programInfoBar"

import ProgramInfoBarItem from "@/components/program/programInfoBarItem"
import ProgramInfoBarArrow from "@/components/program/programInfoBarArrow"
import CompanyTiles from "@/components/company/companyTiles"
import ContactFormDesign from "@/components/shared/contactForm/contactFormDesign"

const OfferIndexPage = () => (
  <>
    <Helmet>
      <title>WILLBUD | MIESZKANIE POD KLUCZ - ZMIANY ARANŻACYJNE</title>
    </Helmet>
    <Layout>
      <HeaderContainer style={{ marginBottom: 0 }}>
        <Container>
          <Row>
            <Col className="content-wrapper" xs="12" sm="12" md="12" lg="5">
              <EstateTitle
                mainTitle="ZMIANY ARANŻACYJNE"
                subTitleBold="Dostosuj lokal do swoich potrzeb"
                subtitle="Tuż po zakupie lokalu,  a jeszcze na etapie realizacji inwestycji, istnieje możliwość dostosowania pomieszczeń do indywidualnych potrzeb i preferencji Klienta poprzez wprowadzenie zmian w instalacji elektrycznej, sanitarnej czy przebiegu ścian działowych."
              />
            </Col>
            <Col xs="12" sm="12" md="12" lg="7" className="image-wrapper">
              <img src={img} alt="ZMIANY ARANŻACYJNE" />
            </Col>
          </Row>
          <Row style={{ marginBottom: "40px" }}>
            <ProgramInfoBarContainer five>
              <h2>Proces</h2>
              <div className="wrapper">
                <ProgramInfoBarItem>
                  <h3>ZGŁASZASZ NIEZBĘDNE ZMIANY</h3>
                </ProgramInfoBarItem>

                <ProgramInfoBarArrow />
                <ProgramInfoBarItem>
                  <h3>SPOTYKASZ SIĘ Z NASZYM ARCHITEKTEM</h3>
                </ProgramInfoBarItem>
                <ProgramInfoBarArrow />
                <ProgramInfoBarItem>
                  <h3>PRZYGOTOWUJEMY PROJEKT</h3>
                </ProgramInfoBarItem>
                <ProgramInfoBarArrow />
                <ProgramInfoBarItem>
                  <h3>REALIZUJEMY PROJEKT</h3>
                </ProgramInfoBarItem>
                <ProgramInfoBarArrow />
                <ProgramInfoBarItem>
                  <h3>ODBIERASZ GOTOWY LOKAL</h3>
                </ProgramInfoBarItem>
              </div>
            </ProgramInfoBarContainer>
          </Row>
        </Container>

        <div className="form-wrapper">
          <Container>
            <Row>
              <Col className="form">
                <ContactFormDesign title={"ZMIANY ARANŻACYJNE"} />
              </Col>
            </Row>
          </Container>
        </div>
        <CompanyTiles />
      </HeaderContainer>
    </Layout>
  </>
)

export default OfferIndexPage
